import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { FaInstagram, FaTwitter, FaBandcamp } from "react-icons/fa";
const IndexPage = ({ data }) => {
  const nodes = data.allMarkdownRemark.edges.map(({ node }) => node);
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Steve Lehman is a composer, performer, educator, and scholar who works across a broad spectrum of experimental musical idioms."
        />
        <title>Steve Lehman</title>
        <body className="bg-gray-50 text-gray-800 font-open-sans" />
      </Helmet>
      <nav className="flex justify-between items-center">
        <h1 className="text-2xl m-6 font-thin">Steve Lehman</h1>
        <div className="flex m-6">
          <a href="https://www.instagram.com/thestevelehman">
            <span class="sr-only">Steve Lehman on Instagram</span>
            <FaInstagram className="w-5 h-5 m-1" />
          </a>
          <a href="https://twitter.com/thestevelehman">
            <span class="sr-only">Steve Lehman on Twitter</span>

            <FaTwitter className="w-5 h-5 m-1" />
          </a>
          <a href="https://stevelehman.bandcamp.com/">
            <span class="sr-only">Steve Lehman on Bandcamp</span>
            <FaBandcamp className="w-5 h-5 m-1" />
          </a>
        </div>
      </nav>
      <div className="flex justify-around md:my-10 lg:my-20">
        <StaticImage
          src="../images/Steve-Lehman.jpg"
          alt="Steve Lehman photo"
          className="max-w-2xl md:max-w-3xl"
        />
      </div>
      <div className="mx-auto max-w-3xl px-4 my-4">
        <div className="mb-8">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{
              __html: nodes.find((node) => node.frontmatter.slug === "/bio")
                .html,
            }}
          />
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="mx-auto max-w-3xl px-4">
          <h2 className="text-2xl font-thin py-8">Contact</h2>
          <div className="pb-8">
            <div
              className="prose max-w-none"
              dangerouslySetInnerHTML={{
                __html: nodes.find(
                  (node) => node.frontmatter.slug === "/contact"
                ).html,
              }}
            />
          </div>
        </div>
      </div>
      <footer className="bg-gray-900">
        <div className="mx-auto max-w-3xl p-4 text-gray-50 text-sm">
          <p className="my-8">© Steve Lehman</p>
        </div>
      </footer>
    </main>
  );
};

export const query = graphql`
  query MyHomePageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { regex: "/(contact|bio)/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
          html
        }
      }
    }
  }
`;

export default IndexPage;
